export const checkValidations = (state) => {
  let newState = {};
  let noTrim = ["undefined", "object", "boolean", "number"];
  let error = "";
  for (let [key, value] of Object.entries(state)) {
    if (Array.isArray(value)) {
      if (value.length < 1) {
        key = key.charAt(0).toUpperCase() + key.slice(1);
        error += `${key},`;
      }
      newState[key] = value;
    } else if (!noTrim.includes(typeof value)) {
      value = value.trim();
      if (value === "") {
        key = key.charAt(0).toUpperCase() + key.slice(1);
        error += `${key},`;
      }
      newState[key] = value;
    } else {
      newState[key] = value;
    }
  }
  if (error !== "") {
    error = error.substring(0, error.length - 1);
    error += " is required!";
  } else {
    error = false;
  }
  console.log("newState===>", newState);
  console.log("error===>", error);
  return { data: newState, error: error };
};

export const checkError = (errors) => {
  let err = "";
  let disabled = false;
  for (var key of Object.keys(errors)) {
    if (errors[key].length > 0) {
      err += `${key} ${errors[key]}`;
      disabled = true;
    }
  }
  err += "";
  console.log("err===>", err);
  return { error: err, disabled };
};
