import React from 'react';
import Button from '@material-ui/core/Button';
import IntlMessages from 'util/IntlMessages';

const Footer = () => {
    return (
        <footer className="app-footer">
            <span className="d-inline-block"><a target="_blank" href="https://www.suffescom.com">Suffescom.com Pvt. Ltd</a> &copy; {getYear()}</span>
        </footer>
    );
  }
;

const getYear = () => {
    let date = new Date();
    return date.getFullYear();
}

export default Footer;
