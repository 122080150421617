import React from 'react';
import { Redirect, Route, Switch, asyncComponent } from '../../../components';
const Customers = ({ match }) => (
  <div className="app-wrapper">
    <Switch>
      <Redirect exact from={`${match.url}/`} to={`${match.url}/list`} />
      <Route path={`${match.url}/list`} component={asyncComponent(() => import('./routes/list'))} />
      <Route path={`${match.url}/add`} component={asyncComponent(() => import('./routes/addRestaurant'))} />
      <Route path={`${match.url}/view/:editId`} component={asyncComponent(() => import('./routes/view'))} />
      <Route path={`${match.url}/viewRes/:resId`} component={asyncComponent(() => import('./routes/viewRestaurant'))} />
      <Route path={`${match.url}/editRes/:resId`} component={asyncComponent(() => import('./routes/addRestaurant'))} />
      <Route path={`${match.url}/edit/:resId/:editId`} component={asyncComponent(() => import('./routes/add'))} />
      <Route path={`${match.url}/edit/:resId`} component={asyncComponent(() => import('./routes/add'))} />
      <Route path={`${match.url}/viewAll/:id`} component={asyncComponent(() => import('./routes/viewAllItem'))} />
      <Route path={`${match.url}/categories/list`} component={asyncComponent(() => import('./routes/categories/list'))} />
      <Route path={`${match.url}/categories/add`} component={asyncComponent(() => import('./routes/categories/add'))} />
      <Route path={`${match.url}/categories/view/:editId`} component={asyncComponent(() => import('./routes/categories/view'))} />
      <Route path={`${match.url}/categories/edit/:editId`} component={asyncComponent(() => import('./routes/categories/add'))} />
      <Route component={asyncComponent(() => import('app/routes/extraPages/routes/404'))} />

    </Switch>
  </div>
);

export default Customers;
