import React from 'react';
import {Redirect, Route, Switch} from 'react-router-dom';
import asyncComponent from '../../../util/asyncComponent';


const Customers = ({match}) => (
  <div className="app-wrapper">
    <Switch>
      <Redirect exact from={`${match.url}/`} to={`${match.url}/list`}/>
      <Route path={`${match.url}/list`} component={asyncComponent(() => import('./routes/list'))}/>
      <Route path={`${match.url}/add`} component={asyncComponent(() => import('./routes/add'))}/>
      <Route path={`${match.url}/view/:editId`} component={asyncComponent(() => import('./routes/view'))}/>
      <Route path={`${match.url}/edit/:editId`} component={asyncComponent(() => import('./routes/add'))}/>
      <Route component={asyncComponent(() => import('app/routes/extraPages/routes/404'))}/>
    </Switch>
  </div>
);

export default Customers;
