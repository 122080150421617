import React from "react";
import { Redirect, Route, Switch, asyncComponent } from "../../../components";
const Reports = ({ match }) => (
  <div className="app-wrapper">
    {console.log("match_utl", match.url)}
    <Switch>
      <Redirect exact from={`${match.url}/`} to={`${match.url}/list`} />
      <Route
        path={`${match.url}/driver-list`}
        component={asyncComponent(() => import("./routes/driver_list"))}
      />
      <Route
        path={`${match.url}/restaurant-list`}
        component={asyncComponent(() => import("./routes/restaurant_list"))}
      />
      <Route
        path={`${match.url}/reportrestaurant-list`}
        component={asyncComponent(() =>
          import("./routes/reportrestaurant_list ")
        )}
      />
      <Route
        path={`${match.url}/reportRestaurant/view/:editId`}
        component={asyncComponent(() =>
          import("./routes/reportRestaurant_view ")
        )}
      />
      <Route
        path={`${match.url}/restaurant/view/:editId`}
        component={asyncComponent(() => import("./routes/restaurant_view"))}
      />

      <Route
        path={`${match.url}/driver/view/:editId`}
        component={asyncComponent(() => import("./routes/view"))}
      />
      <Route
        path={`${match.url}/driver/notes/:editId`}
        component={asyncComponent(() => import("./routes/notes"))}
      />
      {/* <Route
        path={`${match.url}/add`}
          component={asyncComponent(() => import("./routes/add"))}
        /> */}
      {/* <Route
        path={`${match.url}/edit/:editId`}
        component={asyncComponent(() => import("./routes/add"))}
      /> */}
      {/* <Route
        path={`${match.url}/payments/:editId`}
        component={asyncComponent(() => import("./routes/payments"))}
      />
      <Route
        path={`${match.url}/notes/add/:editId`}
        component={asyncComponent(() => import("./routes/addNotes"))}
      />
      <Route
        path={`${match.url}/notes/edit/:noteId`}
        component={asyncComponent(() => import("./routes/addNotes"))}
      />
      <Route
        path={`${match.url}/notes/:editId`}
        component={asyncComponent(() => import("./routes/notes"))}
      /> */}

      <Route
        component={asyncComponent(() =>
          import("app/routes/extraPages/routes/404")
        )}
      />
    </Switch>
  </div>
);

export default Reports;
