import { Link, NavLink, withRouter } from "react-router-dom";
import { connect } from "react-redux";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import { toast, ToastContainer } from "react-toastify";
import { Redirect, Route, Switch } from "react-router-dom";
import asyncComponent from "../util/asyncComponent";
import IntlMessages from "util/IntlMessages";
import Avatar from "@material-ui/core/Avatar";
import CircularProgress from "@material-ui/core/CircularProgress";
import Axios from "../actions/Axios";
import Grid from "@material-ui/core/Grid";
import CardMedia from "@material-ui/core/CardMedia";
import Rating from "@material-ui/lab/Rating";

import {
  TEXTFIELD,
  SUBMIT,
  RESET,
  PASSWORDFIELD,
  UPDATEPASSWORD,
  SELECT,
  SELECT2,
  BUTTON,
  FILEUPLOAD,
  DATEPICKER,
  CHECKBOX,
  GoogleAutocomplete,
  SEARCHFILTER,
  SELECTFILTER,
} from "./Common/InputFields";
import { COUNTRIES, PHONECODES, PHONECODESDATA } from "./Common/countries";
import {
  DataTable,
  DataTableHead,
  DataTableBody,
  DataTablePagination,
  Action,
  ActionPopup,
  TableBody,
  TableRow,
  TableCell,
  moment,
} from "./Common/DataTable";
import InfoCard from "components/InfoCard";
import ContainerHeader from "components/ContainerHeader/index";
import Widget from "components/Widget/index";
import CardBox from "./CardBox/index";
import Paper from "@material-ui/core/Paper";
import CKEditor from "react-ckeditor-component";
import { checkValidations, checkError } from "../actions/helper";
import {
  DialogBox,
  DialogBox1,
  NavLinkButton,
  Accodian,
} from "../components/Common";
import NotFound from "../components/Common/extra/notFound";
import Loading from "../components/Common/extra/loading";
import ImagePreview from "./Common/extra/ImagePreview";
import {
  CardBody,
  CardText,
  Container,
  Row,
  Col,
  Table,
  CardHeader,
  CardImg,
  Card,
  Media,
  Badge,
  Alert,
} from "reactstrap";
import Chip from "@material-ui/core/Chip";

import Input from "@material-ui/core/Input";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import { makeStyles } from "@material-ui/core/styles";
import Accordion from "@material-ui/core/Accordion";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import Typography from "@material-ui/core/Typography";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import CSVReader from "react-csv-reader";
import Status from "./Status";
const COLORS = "#4A863E";
const Logo = require("assets/images/newlogo.png");
const ApiUrl = require("../ApiUrl");
const noImage = ["", null, undefined, "undefined", " ", "null", "none"];

export {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  ExpandMoreIcon,
  Input,
  InputLabel,
  MenuItem,
  FormControl,
  Select,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  makeStyles,
  Logo,
  Table,
  Chip,
  Rating,
  Badge,
  Loading,
  ImagePreview,
  NotFound,
  ApiUrl,
  noImage,
  COLORS,
  Status,
  CardBody,
  CardText,
  Alert,
  Avatar,
  CardImg,
  CardHeader,
  Card,
  CardMedia,
  Media,
  COUNTRIES,
  PHONECODES,
  Link,
  NavLink,
  withRouter,
  Redirect,
  Route,
  Switch,
  asyncComponent,
  connect,
  checkValidations,
  checkError,
  TextField,
  Button,
  UPDATEPASSWORD,
  PHONECODESDATA,
  DialogBox,
  DialogBox1,
  NavLinkButton,
  Accodian,
  IntlMessages,
  CircularProgress,
  Axios,
  ToastContainer,
  toast,
  Widget,
  Paper,
  CardBox,
  CKEditor,
  TEXTFIELD,
  GoogleAutocomplete,
  SUBMIT,
  RESET,
  PASSWORDFIELD,
  SELECT,
  SELECT2,
  SELECTFILTER,
  CSVReader,
  Grid,
  Container,
  Row,
  Col,
  BUTTON,
  FILEUPLOAD,
  DATEPICKER,
  CHECKBOX,
  DataTable,
  DataTableHead,
  DataTableBody,
  DataTablePagination,
  Action,
  ActionPopup,
  TableBody,
  TableRow,
  TableCell,
  moment,
  InfoCard,
  ContainerHeader,
  SEARCHFILTER,
};
