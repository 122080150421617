import React, { useState } from "react";
import { NavLink, withRouter } from "react-router-dom";
import Button from "@material-ui/core/Button";

import IntlMessages from "util/IntlMessages";
export default () => {
  const PERMISSIONS = JSON.parse(localStorage.getItem("permissions"));
  return (
    <ul className="nav-menu">
      <li>
        <NavLink to="/app/dashboard/index">
          <i className="zmdi zmdi-view-dashboard zmdi-hc-fw" />
          <span className="nav-text">Dashboard</span>
        </NavLink>
      </li>
      {PERMISSIONS && PERMISSIONS.includes("customers") && (
        <li className="menu no-arrow">
          <NavLink to="/app/customers">
            <i className="zmdi zmdi-account-box-o zmdi-hc-fw" />
            <span className="nav-text">Customers</span>
          </NavLink>
        </li>
    )}  
      {PERMISSIONS && PERMISSIONS.includes("drivers") && (
       <li>
        <NavLink to="/app/drivers">
          <i className="zmdi zmdi-accounts-alt zmdi-hc-fw" />
          <span className="nav-text">Drivers</span>
        </NavLink>
      </li>
    )}
      {PERMISSIONS && PERMISSIONS.includes("drivers") && (
        <li className="menu collapse-box">
          <Button href="javascript:void(0)" className="cbutton">
            <i className="zmdi zmdi-account-circle zmdi-hc-fw" />
            <span className="nav-text">Drivers</span>
          </Button>
          <ul className="sub-menu">
            <li>
              <NavLink className="prepend-icon cbutton" to="/app/drivers/list">
                <i className="zmdi zmdi-account-circle" />
                <span className="nav-text">All </span>
              </NavLink>
            </li>
            <li>
              <NavLink
                className="prepend-icon cbutton"
                to="/app/drivers/approved"
              >
                <i className="zmdi zmdi-account-circle" />
                <span className="nav-text">Approved </span>
              </NavLink>
            </li>
            <li>
              <NavLink className="prepend-icon cbutton" to="/app/drivers/new">
                <i className="zmdi zmdi-account-circle" />
                <span className="nav-text">New </span>
              </NavLink>
            </li>
          </ul>
        </li>
      )}
      {PERMISSIONS && PERMISSIONS.includes("categories") ? (
        <li>
          <NavLink to="/app/categories">
            <i className="zmdi zmdi-star-circle zmdi-hc-fw" />
            <span className="nav-text">Categories</span>
          </NavLink>
        </li>
      ) : (
        ""
      )}
      {PERMISSIONS && PERMISSIONS.includes("notes") ? (
        <li>
          <NavLink to="/app/notes">
            <i className="zmdi zmdi-star-circle zmdi-hc-fw" />
            <span className="nav-text">Notes</span>
          </NavLink>
        </li>
      ) : (
        ""
      )}
      {PERMISSIONS && PERMISSIONS.includes("menu") ? (
        <li>
          <NavLink to="/app/menu">
            <i className="zmdi zmdi-view-list zmdi-hc-fw" />
            <span className="nav-text">Menu</span>
          </NavLink>
        </li>
      ) : (
        ""
      )}
      {PERMISSIONS && PERMISSIONS.includes("fresh_market") ? (
        <li>
          <NavLink to="/app/fresh-market">
            <i className="zmdi zmdi-shopping-basket zmdi-hc-fw" />
            <span className="nav-text"> Fresh Market </span>
          </NavLink>
        </li>
      ) : (
        ""
      )}
      {PERMISSIONS && PERMISSIONS.includes("items") ? (
        <li>
          <NavLink to="/app/items">
            <i className="zmdi zmdi-shopping-cart zmdi-hc-fw" />
            <span className="nav-text">Items</span>
          </NavLink>
        </li>
      ) : (
        ""
      )}

      {PERMISSIONS && PERMISSIONS.includes("disputes") ? (
        <li>
          <NavLink to="/app/disputes">
            <i className="zmdi zmdi-label zmdi-hc-fw" />
            <span className="nav-text">Disputes</span>
          </NavLink>
        </li>
      ) : (
        ""
      )}
      {PERMISSIONS && PERMISSIONS.includes("vendor") ? (
        <li className="menu collapse-box">
          <Button href="javascript:void(0)" className="cbutton">
            <i className="zmdi zmdi-store zmdi-hc-fw" />
            <span className="nav-text">Restaurants</span>
          </Button>
          <ul className="sub-menu">
            <li>
              <NavLink className="prepend-icon cbutton" to="/app/vendor/list">
                <i className="zmdi zmdi-store" />
                <span className="nav-text">All </span>
              </NavLink>
            </li>
            <li>
              <NavLink
                className="prepend-icon cbutton"
                to="/app/vendor/approved"
              >
                <i className="zmdi zmdi-store" />
                <span className="nav-text">Approved </span>
              </NavLink>
            </li>
            <li>
              <NavLink className="prepend-icon cbutton" to="/app/vendor/new">
                <i className="zmdi zmdi-store" />
                <span className="nav-text">New </span>
              </NavLink>
            </li>
          </ul>
        </li>
      ) : (
        ""
      )}
      {PERMISSIONS && PERMISSIONS.includes("gif") ? (
        <li>
          <NavLink to="/app/gif">
            <i className="zmdi zmdi-dot-circle zmdi-hc-fw" />
            <span className="nav-text">Promotion </span>
          </NavLink>
        </li>
      ) : (
        ""
      )}
      {PERMISSIONS && PERMISSIONS.includes("compare") ? (
        <li>
          <NavLink to="/app/compare">
            <i className="zmdi zmdi-arrows zmdi-hc-fw" />
            <span className="nav-text">Compare </span>
          </NavLink>
        </li>
      ) : (
        ""
      )}
      {PERMISSIONS && PERMISSIONS.includes("pricing") ? (
        <li>
          <NavLink to="/app/pricing">
            <i className="zmdi zmdi-money-box zmdi-hc-fw" />
            <span className="nav-text">Pricing </span>
          </NavLink>
        </li>
      ) : (
        ""
      )}
      {PERMISSIONS && PERMISSIONS.includes("promo_codes") ? (
        <li>
          <NavLink to="/app/promo-codes">
            <i className="zmdi zmdi-label zmdi-hc-fw" />
            <span className="nav-text">Promo Codes</span>
          </NavLink>
        </li>
      ) : (
        ""
      )}
      {PERMISSIONS && PERMISSIONS.includes("push_notification") ? (
        <li>
          <NavLink to="/app/notification">
            <i className="zmdi zmdi-notifications-active zmdi-hc-fw" />
            <span className="nav-text">Push Notification</span>
          </NavLink>
        </li>
      ) : (
        ""
      )}
      {PERMISSIONS && PERMISSIONS.includes("content_pages") ? (
        <li>
          <NavLink to="/app/contentPages">
            <i className="zmdi zmdi-file-text zmdi-hc-fw" />
            <span className="nav-text">Content Pages</span>
          </NavLink>
        </li>
      ) : (
        ""
      )}
      {PERMISSIONS && PERMISSIONS.includes("faq") ? (
        <li>
          <NavLink to="/app/faq">
            <i className="zmdi zmdi-comments zmdi-hc-fw" />
            <span className="nav-text">FAQ</span>
          </NavLink>
        </li>
      ) : (
        ""
      )}

      {PERMISSIONS && PERMISSIONS.includes("promo_codes") && (
        <li>
          <NavLink to="/app/promo-codes">
            <i className="zmdi zmdi-label zmdi-hc-fw" />
            <span className="nav-text">Promo Codes</span>
          </NavLink>
        </li>
      )}
      {PERMISSIONS && PERMISSIONS.includes("content_pages") && (
        <li>
          <NavLink to="/app/contentPages">
            <i className="zmdi zmdi-file-text zmdi-hc-fw" />
            <span className="nav-text">Content Pages</span>
          </NavLink>
        </li>
      )}
      {PERMISSIONS && PERMISSIONS.includes("push_notification") && (
        <li>
          <NavLink to="/app/notification">
            <i className="zmdi zmdi-notifications-active zmdi-hc-fw" />
            <span className="nav-text">Push Notification</span>
          </NavLink>
        </li>
      )}
      {PERMISSIONS && PERMISSIONS.includes("faq") && (
        <li>
          <NavLink to="/app/faq">
            <i className="zmdi zmdi-comments zmdi-hc-fw" />
            <span className="nav-text">Faq</span>
          </NavLink>
        </li>
      )}
      {PERMISSIONS && PERMISSIONS.includes("admin_settings") && (
        <li>
          <NavLink className="prepend-icon cbutton" to="/app/users/list">
            <i className="zmdi zmdi-account" />
            <span className="nav-text">
              <span className="nav-text">Admin Settings</span>
            </span>
          </NavLink>
        </li>
      )}
      {PERMISSIONS && PERMISSIONS.includes("basic_settings") && (
        <li className="menu collapse-box">
          <Button href="javascript:void(0)" className="cbutton">
            <i className="zmdi zmdi-settings zmdi-hc-fw" />
            <span className="nav-text">Settings</span>
          </Button>
          <ul className="sub-menu">
            {PERMISSIONS && PERMISSIONS.includes("basic_settings") && (
              <li>
                <NavLink
                  className="prepend-icon cbutton"
                  to="/app/settings/basic"
                >
                  <i class="zmdi zmdi-album"></i>

                  <span className="nav-text">Basic Settings</span>
                </NavLink>
              </li>
            )}

            {PERMISSIONS && PERMISSIONS.includes("installation_setting") && (
              <li>
                <NavLink
                  className="prepend-icon cbutton"
                  to="/app/settings/installation"
                >
                  <i class="zmdi zmdi-remote-control"></i>

                  <span className="nav-text">Installation Settings</span>
                </NavLink>
              </li>
            )}
            {PERMISSIONS && PERMISSIONS.includes("mail_settings") && (
              <li>
                <NavLink
                  className="prepend-icon cbutton"
                  to="/app/settings/mail"
                >
                  <i class="zmdi zmdi-email"></i>

                  <span className="nav-text">Mail templates</span>
                </NavLink>
              </li>
            )}
            {PERMISSIONS && PERMISSIONS.includes("sms_settings") && (
              <li>
                <NavLink
                  className="prepend-icon cbutton"
                  to="/app/settings/sms"
                >
                  <i class="zmdi zmdi-comment-text"></i>

                  <span className="nav-text">SMS Templates</span>
                </NavLink>
              </li>
            )}
          </ul>
        </li>
      )}
    </ul>
  );
};
